import styled from "styled-components";

import React, {useCallback, useState} from "react";
import "../../font/font.css";
import {
    appStore,
    bannerM,
    downloadCompany,
    googlePlay,
    main1,
    main1M,
    main2,
    main2M,
    main3,
    main3M
} from "../../images";
import Header from "../../components/Header";
import {isMobile} from 'react-device-detect';
import StoreButton from "../../components/container/StoreButton";
import HomeTopBannerM from "../../components/home/HomeTopBannerM";
import HomeMedilanceIntroM from "../../components/home/HomeMedilanceIntroM";
import HomeMedibodyIntroM from "../../components/home/HomeMedibodyIntroM";

const Main1 = styled.div`
    width: 100%;
    position: absolute;
    min-width: 800px;
`;

function Home() {
    const url = 'https://medilancekorea.com/company.pdf';

    const [isDownloading, setIsDownloading] = useState(false);

    const onClickImgLink = useCallback((srcUrl, name) => {
        setIsDownloading( true );
        fetch(srcUrl, { method: 'GET' }).then((res) => res.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout((_) => {
                window.URL.revokeObjectURL(url);
            }, 1000);
            a.remove();
        }).catch((err) => {
            console.error('err', err);
        }).finally(() => setIsDownloading( false ));
    }, []);


    return (
        <>
            {/*// 모바일 환경 웹*/}
            <div className={'relative md:hidden'}>
                {/* 상단 배너 */}
                <HomeTopBannerM onDownloadClicked={ () => onClickImgLink(url, '(주)바로웰 회사소개서.pdf') } isDownloading={isDownloading}/>
                {/* 바로웰 소개 */}
                <HomeMedilanceIntroM />
                <HomeMedibodyIntroM />

                {/*<div className={'relative flex w-full h-full'}>*/}
                {/*    <img src={main1} style={{width: '100%'}}/>*/}
                {/*    <div className={'absolute bottom-[5%] right-[15%] cursor-pointer'}*/}
                {/*         onClick={() => onClickImgLink(url, '(주)바로웰 회사소개서.pdf')}>*/}
                {/*        <img className={'w-[170px]'} src={downloadCompany} alt={'회사소개 다운로드'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'flex w-full h-full'}>*/}
                {/*    <img src={main2} style={{width: '100%'}} alt={'메인 2'}/>*/}
                {/*    /!* 추후 이미지 하단 스토어 이동 이미지 *!/*/}
                {/*    <div className={'absolute left-[16.5%] bottom-[43%] flex flex-row gap-x-4'}>*/}
                {/*        <StoreButton diff={'google'}/>*/}
                {/*        <StoreButton diff={'apple'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'flex w-full h-full'}>*/}
                {/*    <img src={main3} style={{width: '100%'}} alt={'메인 3'}/>*/}
                {/*    /!* 추후 이미지 하단 스토어 이동 이미지 *!/*/}
                {/*    <div className={'absolute left-[16.5%] bottom-[10%] flex flex-row gap-x-4'}>*/}
                {/*        <StoreButton diff={'google'}/>*/}
                {/*        <StoreButton diff={'apple'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            {/*// 데스크탑 환경 웹*/}
            <div className={'hidden md:block md:relative'}>
                <div className={'relative flex w-full h-full'}>
                    <img src={main1} style={{width: '100%'}}/>
                    <div className={'absolute bottom-[5%] right-[15%] cursor-pointer'}
                         onClick={() => onClickImgLink(url, '(주)바로웰 회사소개서.pdf')}>
                        <img className={'w-[170px]'} src={downloadCompany} alt={'회사소개 다운로드'}/>
                    </div>
                </div>
                <div className={'flex w-full h-full'}>
                    <img src={main2} style={{width: '100%'}} alt={'메인 2'}/>
                    {/* 추후 이미지 하단 스토어 이동 이미지 */}
                    <div className={'absolute left-[16.5%] bottom-[43%] flex flex-row gap-x-4'}>
                        <StoreButton diff={'google'}/>
                        <StoreButton diff={'apple'}/>
                    </div>
                </div>
                <div className={'flex w-full h-full'}>
                    <img src={main3} style={{width: '100%'}} alt={'메인 3'}/>
                    {/* 추후 이미지 하단 스토어 이동 이미지 */}
                    <div className={'absolute left-[16.5%] bottom-[10%] flex flex-row gap-x-4'}>
                        <StoreButton diff={'google'}/>
                        <StoreButton diff={'apple'}/>
                    </div>
                </div>
            </div>
        </>
    );

    // return (
    //     <>
    //             <div className={ 'block md:hidden' }>
    //                 <div style={{display: 'flex', flexDirection: 'column'}}>
    //                     <img src={main1M} style={{width: '100%'}}/>
    //                 </div>
    //                 <div style={{display: 'flex', flexDirection: 'column'}}>
    //                     <img src={main2M} style={{width: '100%'}}/>
    //                 </div>
    //                 <div style={{display: 'flex', flexDirection: 'column'}}>
    //                     <img src={main3M} style={{width: '100%'}}/>
    //                 </div>
    //             </div>
    //
    //             <div className={ 'hidden md:block' }>
    //                 <img src={main1} style={{width: '100%'}}/>
    //                 <img src={main2} style={{width: '100%'}}/>
    //                 <img src={main3} style={{width: '100%'}}/>
    //             </div>
    //     </>
    // )
}

export default Home;