import styled from "styled-components";

import {useEffect, useState} from "react";
import "../../font/font.css";
import {useHistory, useLocation} from "react-router-dom";
import {media1, mediaM, testImage} from "../../images";
import {isMobile} from "react-device-detect";
import {mediaData, mediaVideoData} from "./media.data";
import useHistoryState from "../../components/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import {paginate} from "../utils";
import BannerContainerM from "../../components/container/BannerContainerM";
import DefaultLayout from "../../components/DefaultLayout";

const Row = styled.div`
    display: flex;

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        flex: 1 1 40%;
    }
`;

function Media() {
    const history = useHistory();
    const [data, setData] = useHistoryState([], 'data');
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');

    useEffect(() => {
        setPageCount(mediaData.length);
        setData(paginate(mediaData.sort((a, b) => new Date(b.date) - new Date(a.date)), pageActive, 6));
    }, [pageActive]);

    return (
        <DefaultLayout showFooter={false}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <img className={'hidden md:block'} src={media1} style={{width: '100%', minWidth: '900px'}}/>
                <BannerContainerM title={'미디어룸'} content={'바로웰의 다양한 소식을 전해드립니다.'}/>
            </div>
            <div className={'px-[20px]'}>
                <div className={'py-[32px] 2xl:px-[300px] md:py-[120px] flex flex-col items-center'}>
                    <div className={'w-full'}>
                        <div className={'flex flex-col md:px-0'}>
                            <span className={'text-18px md:text-[40px] font-bold'}>뉴스</span>
                            <div className={'h-[16px] md:h-[30px]'}/>
                            <div className={'flex flex-col w-full xl:grid xl:grid-cols-2 gap-[20px]'}>

                                {data.map((v) => {
                                    return <>
                                        <div
                                            className={'flex flex-row items-center rounded-2xl bg-[rgb(248_248_248)] hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}>
                                            <a href={v.link} target="_blank">
                                                <img
                                                    className={'rounded-l-2xl aspect-square h-[112px] md:w-[198px] md:h-auto object-cover'}
                                                    src={v.thumbnail}/>
                                            </a>
                                            <div className={'flex px-[12px] md:px-[20px] flex-col w-full'}>
                                                <span
                                                    className={'text-12px md:text-[14px] font-medium md:font-semibold text-[rgb(80,85,92)] md:line-clamp-2 line-clamp-1'}>{v.date}
                                                </span>
                                                <div className={'h-[8px]'}/>
                                                <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                                    <span
                                                        className={'text-16px md:text-18px font-bold md:font-semibold line-clamp-2'}>{v.title}
                                                    </span>
                                                </a>
                                                <div className={'h-[8px]'}/>
                                                <span
                                                    className={'md:text-base md:font-normal text-12px font-medium line-clamp-2'}>{v.from}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                            <div className={'mt-[16px]'}>
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={5}
                                    limit={6}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'h-[64px]'}/>
                    <div className={'w-full'}>
                        <span className={'text-18px md:text-[40px] font-bold'}>유튜브</span>
                        <div className={'h-[16px] md:h-[30px]'}/>
                        <div className={'grid grid-cols-2 xl:grid-cols-3 gap-[20px]'}>
                            {mediaVideoData.map((v) => {
                                return <div
                                    className={'flex flex-col hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}>
                                    <a href={v.link} target="_blank">
                                        <img className={'w-full h-[200px] rounded-t-2xl object-cover'} src={v.thumbnail}
                                             alt={'섬네일'}/>
                                    </a>
                                    <div className={'px-4 py-4 h-28 rounded-b-2xl'}
                                         style={{backgroundColor: '#f8f8f8'}}>
                                        <div style={{fontSize: '0.9rem', color: '#646464'}}>
                                            {v.date}
                                        </div>
                                        <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                            <div
                                                className={'text-16px md:text-18px font-bold md:font-semibold line-clamp-2'}>
                                                {v.title}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default Media;