// import {headerLogo, logo} from "../../image";
import styled from "styled-components";
import {cancelButton, logo, menu} from "../images";
import {useHistory} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {useState} from "react";
import {useRecoilState} from "recoil";
import {recoilState} from "../pages/data/data";
import {clsx} from "clsx";
import {useNavigate} from "react-router";

function Header({ scrolled }) {
    const history = useHistory();
    const isHome = history.location.pathname === '/home'
    const isMedia = history.location.pathname === '/media'
    const isCoop = history.location.pathname === '/coop'
    const isEducation = history.location.pathname === '/education'
    const isService = history.location.pathname === '/service'
    const isInquiry = history.location.pathname === '/inquiry'
    const [isMenu, setIsMenu] = useRecoilState(recoilState)

    return (
        <>
            <>
                {/* 모바일 헤더 열렸을 떄 */}
                {isMenu
                    ?
                    <>
                        <div className={ 'm-auto w-full flex absolute md:hidden z-[95] bg-[#1e2126]' }>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                margin: '0 auto'
                            }}>
                                <div>
                                    <img src={logo} style={{width: '30%', cursor: 'pointer', padding: '3% 0 2% 0'}}
                                         onClick={(e) => history.push('/home')}/>
                                </div>
                                <div style={{textAlign: "right", display: 'flex'}}>
                                    <img src={cancelButton}
                                         style={{width: '90%', cursor: 'pointer', padding: '3% 0 2% 0'}}
                                         onClick={(e) => setIsMenu(true)}/>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    // 모바일 헤더 닫혔을 때
                    <div className={ clsx('mx-auto py-2 w-full flex block fixed md:hidden z-[95] transition duration-500', [scrolled ? 'bg-black-grey' : '']) }>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '90%',
                            margin: '0 auto'
                        }}>
                            <div>
                                <img src={logo} style={{width: '30%', cursor: 'pointer', padding: '3% 0 2% 0'}}
                                     onClick={(e) => history.push('/home')}/>
                            </div>
                            <div style={{textAlign: "right", display: 'flex'}}>
                                <img src={menu} style={{width: '100%', cursor: 'pointer', padding: '3% 0 2% 0'}}
                                     onClick={(e) => setIsMenu(true)}/>
                            </div>
                        </div>
                    </div>
                }
            </>

            {/* 데스크탑 헤더 */}
            <div className={ clsx('hidden md:block md:fixed top-0 pt-6 z-[9999] w-full transition duration-500', [scrolled ? 'bg-black-grey' : '']) }>
                <div className={'w-full flex flex-row justify-around'}>
                    <div>
                        <img src={logo}
                             className={'w-[124px] pb-6 -translate-y-[4px] cursor-pointer'}
                            // style={{width: '40%', cursor: 'pointer', paddingBottom: '4%', translate: '0px -5px'}}
                             onClick={(e) => history.push('/home')}/>
                    </div>
                    {/* 메뉴 */}
                    <div className={'text-center flex flex-row gap-x-1 lg:gap-x-10 xl:gap-x-16'}>
                        <Menu isActive={isHome} menuName={'회사소개'} path={'/home'}/>
                        <Menu isActive={isMedia} menuName={'미디어룸'} path={'/media'}/>
                        <Menu isActive={isCoop} menuName={'협력센터'} path={'/coop'}/>
                        <Menu isActive={isEducation} menuName={'교육 및 자격증'} path={'/education'}/>
                        <Menu isActive={isService} menuName={'찾아가는 서비스'} path={'/service'}/>
                        <Menu isActive={isInquiry} menuName={'문의'} path={'/inquiry'}/>
                    </div>
                    {/*  모바일 헤더  */}

                </div>
            </div>
        </>
    )
}

const Menu = ({isActive, menuName, path}) => {
    const history = useHistory();
    return <div
        className={clsx(isActive ? 'border-b-[3px] border-white' : '', ['px-4 hover:scale-110 transition duration-150'])}>
        <h4 className={'text-white cursor-pointer'} onClick={() => history.push(path)}>{menuName}</h4>
    </div>
}

export default Header;