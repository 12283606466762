/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import {isMobile} from 'react-device-detect';
import {appleButton, footer, footerM, googleButton, logo} from "../images";
import StoreButton from "./container/StoreButton";

// reactstrap components

const Font = styled.div`
    font-size: 0.6em;
`;

function Footer() {
    return (
        <div className={'bg-black-grey py-[100px] flex flex-row w-full'}>
            <div
                className={'flex flex-col w-full px-[24px] md:px-0 xl:px-48 2xl:px-96 xl:items-stretch md:items-center'}>
                <div className={'flex flex-col gap-y-8 2xl:gap-y-0 xl:flex-row justify-between md:items-center'}>
                    <div>
                        <div>
                            <img className={'h-8 md:h-7'} src={logo} alt={'로고'}/>
                        </div>
                        <div className={'h-6'}/>
                        <div className={'flex flex-col gap-y-1'}>
                            <span
                                className={'text-white font-semibold md:font-medium text-16px md:text-lg'}>주식회사 바로웰</span>
                            <span className={'text-white font-light md:font-normal text-12px md:text-sm'}>케빈 성 / 637-86-03098</span>
                            <span className={'text-white font-light md:font-normal text-12px md:text-sm'}>Fax : 070-7500-6400 / Tel : 070-5066-6400</span>
                            <span className={'text-white font-light md:font-normal text-12px md:text-sm'}>Email : kevin.sung@medilancekorea.com / Web : www.medilancekorea.com</span>
                            <span className={'text-white font-light md:font-normal text-12px md:text-sm mt-[10px]'}>경기도 구리시 아차산로 510 삼흥빌딩 6층</span>
                        </div>
                    </div>
                </div>
                <div className={'h-16'}/>
                <span className={'text-gray-600'}>© Barowell All rights reserved.</span>
            </div>
        </div>
    );
}

export default Footer;
