import React from "react";
import {medibodyIntro, medilanceIntro} from "../../images";
import StoreButton from "../container/StoreButton";

const HomeMedibodyIntroM = () => {
    return (
        <div className={ 'p-home-intro bg-gradient-to-b from-white to-[rgb(255_245_227)]' }>
            <div className={ 'flex flex-col items-center' }>
                {/* 앱 이름 및 앱 설명 */}
                <span className={ 'text-16px font-semibold text-[rgb(235,218,186)]' }>
                    Medibody
                </span>
                <div className={ 'h-[12px]' } />
                <span className={'whitespace-pre-wrap text-22px font-bold text-center'}>
                    { '바로웰은 헬스/메디케어\n통합정보 플랫폼\n메디바디를 운영합니다.' }
                </span>
                <div className={ 'h-[20px]' } />

                {/*  칩  */}
                <div className={ 'flex flex-wrap gap-x-[6px]' }>
                    <MedibodyChip text={ '메디바디' } />
                    <MedibodyChip text={ '통합 정보제공' } />
                    <MedibodyChip text={ '위치기반 플랫폼' } />
                </div>
                <div className={ 'h-[56px]' } />

                {/*  앱 이미지  */}
                <div className={ 'w-full h-auto' }>
                    <img src={medibodyIntro} alt={ '메디바디 인트로' } />
                </div>
                <div className={ 'h-[32px]' } />

                {/*  구글 로그인  */}
                <div className={ 'flex flex-row gap-x-[12px] px-[6px]' }>
                    <StoreButton diff={'google'} androidLink={ 'https://play.google.com/store/apps/details?id=com.rightcode.medibody' } />
                    <StoreButton diff={'apple'} iosLink={ 'https://apps.apple.com/kr/app/%EB%A9%94%EB%94%94%EB%B0%94%EB%94%94/id6480168239' }/>
                </div>
            </div>
        </div>
    );
}

const MedibodyChip = ({ text }) => {
    return (
        <div className={ 'p-[8px_12px_8px_12px] rounded-[32px] border border-[rgb(215,193,149)]' }>
            <span className={ 'text-[rgb(215,193,149)] font-semibold text-14px' }>{text}</span>
        </div>
    );
}

export default HomeMedibodyIntroM;